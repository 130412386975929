import React from 'react'
import FAQItem from './FAQItem'
import { FAQItems } from '../../../common/constants/faq'
import Divider from '../../divider/Divider'
import { CategoryResponse } from '../../../types/categories/responses/category-response'
import { GameCategories } from '../../../common/enums/game-categories'

type Props = {
  category?: CategoryResponse
  isHome?: boolean
}

export default function FAQSection({ category, isHome = false }: Props) {
  const items = []

  switch (category?.key) {
    case GameCategories.leagueOfLegends:
      if (isHome) {
        // items.push({
        //   questionName: 'Is this an ELO Boost?',
        //   acceptedAnswerText:
        //     "It's much more than an Elo Boost. Our service is not only completely safe but also offers the opportunity to learn strategies from a top-ranked teammate and have a great time in the process. It's just like playing a game with a friend.",
        // })

        items.push({
          questionName: 'Why pricing varies?',
          acceptedAnswerText:
            "Tapin's pricing model is dynamic, adjusting rates based on your League of Legends rank.",
        })

        // items.push({
        //   questionName: 'Is ELO Boost safe?',
        //   acceptedAnswerText:
        //     "There's no account sharing involved in Elo Boost, ensuring complete safety. It's just like playing a game with a friend.",
        // })

        items.push({
          questionName: 'Where to enter my League of Legends rank?',
          acceptedAnswerText:
            "We'll analyze your gaming profile using your username and region. You don't need to enter your rank manually.",
        })

        items.push({
          questionName: 'Is it safe to play with a Tapin teammate?',
          acceptedAnswerText:
            "There's no account sharing involved, ensuring complete safety. It's just like playing a game with a friend.",
        })
      } else {
        // items.push({
        //   questionName: 'Is this a LoL Boosting?',
        //   acceptedAnswerText:
        //     "It's much more than a LoL Boosting. Our service is not only completely safe but also offers the opportunity to learn strategies from a top-ranked teammate and have a great time in the process. It's just like playing a game with a friend.",
        // })

        // items.push({
        //   questionName: 'Is LoL Boosting safe?',
        //   acceptedAnswerText:
        //     "There's no account sharing involved in LoL Boosting, ensuring complete safety. It's just like playing a game with a friend.",
        // })
        items.push({
          questionName: 'Where to enter my League of Legends rank?',
          acceptedAnswerText:
            "We'll analyze your gaming profile using your username and region. You don't need to enter your rank manually.",
        })

        items.push({
          questionName: 'Is it safe to play with a Tapin teammate?',
          acceptedAnswerText:
            "There's no account sharing involved, ensuring complete safety. It's just like playing a game with a friend.",
        })
      }
      break
    case GameCategories.valorant:
      // items.push({
      //   questionName: 'Is this a Valorant Boosting?',
      //   acceptedAnswerText:
      //     "It's much more than a Valorant Boosting. Our service is not only completely safe but also offers the opportunity to learn strategies from a top-ranked teammate and have a great time in the process. It's just like playing a game with a friend.",
      // })

      // items.push({
      //   questionName: 'Is Valorant Boosting safe?',
      //   acceptedAnswerText:
      //     "There's no account sharing involved in Valorant Boosting, ensuring complete safety. It's just like playing a game with a friend.",
      // })

      items.push({
        questionName: 'Is it safe to play with a Tapin teammate?',
        acceptedAnswerText:
          "There's no account sharing involved, ensuring complete safety. It's just like playing a game with a friend.",
      })

      break
    case GameCategories.csgo:
      // items.push({
      //   questionName: 'Is this a CS2 Boosting?',
      //   acceptedAnswerText:
      //     "It's much more than a CS2 Boosting. Our service is not only completely safe but also offers the opportunity to learn strategies from a top-ranked teammate and have a great time in the process. It's just like playing a game with a friend.",
      // })

      // items.push({
      //   questionName: 'Is CS2 Boosting safe?',
      //   acceptedAnswerText:
      //     "There's no account sharing involved in CS2 Boosting, ensuring complete safety. It's just like playing a game with a friend.",
      // })

      items.push({
        questionName: 'Is it safe to play with a Tapin teammate?',
        acceptedAnswerText:
          "There's no account sharing involved, ensuring complete safety. It's just like playing a game with a friend.",
      })

      break
    case GameCategories.fortnite:
      items.push({
        questionName: 'Is it safe to play with a Fortnite coach?',
        acceptedAnswerText:
          "There's no account sharing involved, ensuring complete safety. It's just like playing a game with a friend.",
      })
      break
    default:
      break
  }

  items.push(...FAQItems)

  return (
    <div className={'mt-4 flex flex-col'}>
      <div className={'flex flex-col'}>
        {/*<h3 className={'font-oswald text-xl font-semibold text-darul-300'}>*/}
        {/*  FAQ*/}
        {/*</h3>*/}

        <h3 className={'text-2xl-normal uppercase text-white'}>
          Frequently asked questions
        </h3>
        <div className={'mt-2'}>
          <Divider backgroundColor={'border-fizz-500'} />
        </div>

        {/*<h4 className={'text-3xl-bold mt-3 text-center text-white'}>*/}
        {/*  Frequently asked questions*/}
        {/*</h4>*/}
      </div>

      <div className={'mt-4 flex flex-col'}>
        {items.map((item, index) => {
          return (
            <div key={item.questionName} className={'mt-8 w-full'}>
              <FAQItem
                title={item.questionName}
                description={item.acceptedAnswerText}
                hasDivider={index !== items.length - 1}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}
