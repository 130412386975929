import React from 'react'

interface Props {
  title: string
  description: string
  hasDivider?: boolean
}

export default function FAQItem({ title, description, hasDivider }: Props) {
  // const [isOpen, setIsOpen] = useState(false)

  return (
    <div className={'flex flex-col md:w-full'}>
      <div
        className={'flex w-full cursor-pointer flex-row justify-between'}
        onClick={(e) => {
          e.preventDefault()
          //setIsOpen(!isOpen)
        }}
      >
        <h3 className={'text-body-medium text-white'}>{title}</h3>

        {/*<div className={'shrink-0'}>*/}
        {/*  {isOpen ? (*/}
        {/*    <Image src={MinusIcon} alt={'Minus icon'} width={24} height={24} />*/}
        {/*  ) : (*/}
        {/*    <Image src={PlusIcon} alt={'Plus icon'} width={24} height={24} />*/}
        {/*  )}*/}
        {/*</div>*/}
      </div>

      <div className={'mt-3 w-fit overflow-hidden text-darul-100'}>
        <p className={'text-body-light displayElement opacity-100'}>
          {description}
        </p>
      </div>

      {/*{hasDivider && (*/}
      {/*  <div className={'displayElement w-full'}>*/}
      {/*    <Divider />*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  )
}
