import React, { useEffect, useRef, useState } from 'react'
import Image from 'next/image'
import { CategoryResponse } from '../../types/categories/responses/category-response'
import { useOnlineTeammates } from '../../hooks/games/useOnlineTeammates'
import { GameTeammatesV2 } from '../../api/creators/getGamingTeammates'
import { useGameCard } from '../../hooks/games/useGameCard'
import CheckMarkIcon from '../../public/static/category-check-mark.png'

type Props = {
  category: CategoryResponse
  isSelected: boolean
  enabled: boolean
  index?: number
  hideDetails?: boolean
  gameTeammates: GameTeammatesV2
}

export default function GameCardV6({
  category,
  isSelected,
  hideDetails = false,
  enabled = false,
  index = 0,
  gameTeammates,
}: Props) {
  const { getTeammatesByCategoryV2 } = useGameCard()
  const teammates = getTeammatesByCategoryV2(category.key, gameTeammates)

  const { calculateTeammates } = useOnlineTeammates()
  const [count, setCount] = useState(calculateTeammates(category, true))
  const interval = useRef<any | null>(null)

  useEffect(() => {
    setCount(calculateTeammates(category))
    interval.current = setInterval(() => {
      setCount(calculateTeammates(category))
    }, 60000)

    return () => {
      clearInterval(interval.current)
    }
  }, [])

  return (
    <div className={'mr-5 h-64 w-45'}>
      {/*<div className={'relative h-28 w-full'}>*/}
      <div
        className={`relative h-44 w-45 cursor-pointer overflow-hidden rounded-lg group ${
          isSelected ? 'border-3 border-bung-400 opacity-95' : 'opacity-60'
        }`}
      >
        <Image
          src={
            category.imageAsset?.imageUrl ??
            category.landscapeImageAsset?.imageUrl ??
            process.env.NEXT_PUBLIC_DEFAULT_IMAGE_URL!
          }
          className={`rounded-lg object-cover transition-transform duration-300 ${
            !enabled ? '' : ''
          } ${isSelected ? 'image-transform-active' : 'image-transform'}`}
          alt={`${category.name}`}
          fill={true}
          priority={index === 0 || index === 1}
        />

        {category?.logoImageAsset?.imageUrl && (
          <div
            className={
              'absolute h-32 top-2/3 left-1/2 -translate-x-1/2 -translate-y-1/2 w-32 self-center mb-3'
            }
          >
            <Image
              src={category?.logoImageAsset?.imageUrl}
              className={`object-contain transition-transform duration-500 ${isSelected ? 'image-transform-active' : 'image-transform'} z-10`}
              alt={`${category.name}`}
              fill={true}
              priority={index === 0 || index === 1}
            />
          </div>
        )}

        {isSelected && (
          <div className={'absolute h-5 top-0 right-0 w-5'}>
            <Image
              src={CheckMarkIcon}
              className={`object-contain transition-transform duration-500 ${isSelected ? 'image-transform-active' : 'image-transform'} z-10`}
              alt={`${category.name}`}
              fill={true}
              priority={index === 0 || index === 1}
            />
          </div>
        )}
      </div>
      {/*</div>*/}

      <h3
        className={`text-body-medium mt-2 w-45 truncate px-3 text-white ${
          enabled ? 'cursor-pointer opacity-95' : 'opacity-40'
        }`}
      >
        {category.key === 'fifa'
          ? 'EA Sports FC 24'
          : category.key === 'call-of-duty-warzone'
            ? 'CoD: Black Ops 6'
            : category.name}
      </h3>

      {enabled ? (
        <div
          className={`flex flex-col ${hideDetails ? 'hidden' : ''} 
             mx-3 mt-1 cursor-pointer opacity-95`}
        >
          <div className={'flex flex-row items-center gap-1'}>
            {/*<div className={'h-1 w-1 rounded-full bg-[#1DCEA4]'}></div>*/}

            <p className={'text-color-gray4 text-xs-normal text-xs-normal'}>
              {teammates?.onlineTeammatesCount ?? 0} teammates
            </p>
          </div>
          <div
            className={`flex flex-row items-center gap-1
             ${
               category.recentRequests && category.recentRequests !== '0'
                 ? ''
                 : 'invisible'
             }
             
             ${hideDetails ? 'hidden' : ''}`}
          >
            {/*<div className={'h-1 w-1 rounded-full bg-[#F4A400]'}></div>*/}
            <p className={'text-color-gray4 text-xs-normal'}>
              {category.recentRequests} recent sessions
            </p>
          </div>
        </div>
      ) : (
        <div
          className={`flex flex-col ${hideDetails ? 'hidden' : ''} 
             mx-3 mt-1 cursor-pointer opacity-95 `}
        >
          <div className={'flex flex-row'}>
            <p className={'text-color-gray4 text-body-medium text-xs-normal'}>
              Coming soon
            </p>
          </div>
          <div
            className={`invisible flex flex-row
            
             ${hideDetails ? 'hidden' : ''}`}
          >
            <p className={'text-color-gray4 text-xs-normal font-lexend'}>
              {category.recentRequests} recent sessions
            </p>
          </div>
        </div>
      )}
    </div>
  )
}
